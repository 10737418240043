.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(10px);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    z-index: 99999;
}

.modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
}

.modal-content {
    position: relative;
    margin: 20px;
    border-radius: 20px;
    background-color: white;
    padding: 20px 20px 20px 20px;
    transform: translateY(-50px);
    transition: opacity 0.3s, transform 0.3s;
    width: 70vw;
}

@media screen and (max-width: 768px) {
    .modal-content {
        width: 95vw;
    }
}

.modal-close-button {
    position: absolute;
    top: -3px;
    right: -5px;
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: white;
    border-color: white;
    border: 1px;
    border-radius: 50%;
    box-shadow: 0px 5px 6px 1px rgba(0, 0, 0, 0.2);

}

.modal-close-button:hover {
    background: white;
    border-color: white;
    transition: none !important;
    border: 1px;
    transform: scale(1.08);
}

@media (hover: none) {
    .modal-close-button:hover {
        transform: none;
    }
}

/*open*/

.modal--entering,
.modal--entered {
    opacity: 1;
}

.modal--entering .modal-content,
.modal--entered .modal-content {
    transform: translateY(0);
}

/*close*/

.modal--exiting,
.modal--exited {
    opacity: 0;
}

.modal--exiting .modal-content,
.modal--exited .modal-content {
    transform: translateY(-50px);
}