.modal-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    /* Начальная непрозрачность */
    transition: opacity 0.3s ease;
    /* Плавный переход для фона */
    visibility: hidden;
    /* Скрываем модалку по умолчанию */
}

.modal-image-overlay.show {
    opacity: 1;
    /* Полная непрозрачность при открытии */
    visibility: visible;
    /* Делаем видимой при открытии */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
        /* Начальный размер */
    }

    to {
        opacity: 1;
        transform: scale(1);
        /* Конечный размер */
    }
}

.modal-image-content {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    max-width: 95vh;
    max-height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.2s ease-in;
    flex-direction: column;
}

.modal-image-content img {
    max-width: 100%;
    max-height: calc(95vh - 60px);
    object-fit: contain;
    transition: transform 0.3s ease;
    cursor: grab;
}

.modal-content img:active {
    cursor: grabbing;
    /* Курсор при активном перетаскивании */
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    /* Кнопки занимают всю ширину */
    position: relative;
}

.zoom-button {
    background-color: #7a7a7a;
    border: 1px solid black;
    padding: 10px;
    flex-grow: 0 !important;
    border-radius: 50% !important;
    width: 40px !important;
    height: 40px !important;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    cursor: pointer;
    font-size: 18px;
    margin: 0 5px;
    flex-shrink: 0;
    opacity: 0.5;

    &:hover {
        border-color: black;
        background-color: #7a7a7a;
        opacity: 1;
    }
}

.zoom-button.zoom-in {
    right: 50px;
}

.zoom-button.zoom-out {
    right: 10px;
}