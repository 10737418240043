.footer {
  padding: 2rem 1rem 1.5rem;
  @include flex(center, flex-start, row);
  width: 100%;
  max-width: 1080px;
  margin: auto;
  min-height: 10vh;
  border-top: 1px solid $color-lightgrey;

  @media (max-width: 700px) {
    display: block;
  }
}

.footer-section {
  flex: 1 1 12rem;
  margin-right: 2rem;

  h3 {
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
  }

  ul {
    gap: 5rem;

    li {
      padding: 0 0 0.5rem 0;
    }

    li a {
      font-size: 0.7rem;
    }
  }
}

.footer-socials {
  flex: 3 1 30rem;
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 1rem;

  @media (max-width: 1000px) {
    flex: 1 1 20rem;
  }
}

.social-icon-container {
  border: 1px solid $color-grey;
  padding: 0.3rem;
  border-radius: 50%;

  a {
    @include flex(center, center, row);

    img {
      height: 24px;
    }
  }
}