.container {
    max-width: 85vw;
    width: 100%;
    margin: auto;
    max-width: 1080px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.search-resaults {
    padding: 20px;
}

.search.container {
    height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
    transition: height 0s ease, opacity 0.3s ease;
}

.search.container.active {
    height: 100%;
    opacity: 1;
}

.main-view {
    height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
    transition: height 0s ease, opacity 1.3s ease;
}

.main-view.active {
    height: 100%;
    opacity: 1;
}

.flex {
    @include flex(center, flex-start, row);

    @media (max-width: 1100px) {
        display: block;
    }
}

.flex-start {
    @include flex(flex-start, flex-start, row);

    @media (max-width: 850px) {
        display: block;
    }
}

.space-between {
    margin-bottom: 0.5rem;
    width: 100%;
    @include flex(space-between, center, row);
}

.side-container {
    flex: 1;
}

.flex-1 {
    flex: 1;

    @media (max-width: 850px) {
        margin: 2rem 0 0 0;
    }

    @media (min-width: 1100px) {
        margin-left: 2rem;
    }
}

.flex-2 {
    flex: 2;
}

.divider {
    width: 100%;
    display: flex;
    gap: 1rem;
}

@media (max-width: 768px) {
    .divider {
        display: table;
    }
}

.special-label {
    display: none !important;
}

.line {
    margin: 0.5rem 0;
    height: 1px;
    width: 100%;
    background: $color-grey;
}

.line-divider {
    margin: 2rem 0;
    height: 1px;
    width: 100%;
    background: $color-lightgrey;
}


.second-button {
    border: 2px solid $color-smoke;
    background: $color-smoke;
    color: $color-blue;
    gap: 1rem;
    flex-grow: 0;
    flex-shrink: 1;
    transition: all 0.1s ease-in-out;

    &:hover {
        background: $color-blue;
        color: white;
        border: 2px solid $color-blue;
    }

    @include flex(center, center, row);

    @media (max-width: 1000px) {
        span {
            display: none;
        }

    }
}

.txt {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    white-space: nowrap;
}

.grey {
    background: #f4f4f4;
    color: $color-grey;
}

.red {
    background: #f2c4c6;
    color: #6e3a39;
}

.yellow {
    background: #fcee8e;
    color: #7c6d29;
}

.green {
    background: #c3f0d2;
    color: #3b5f45;
}

.input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid $color-lightgrey;
    background: white;
    border-radius: 3px;

    input {
        margin-bottom: 0;
        border: none;
    }
}

.input-label {
    display: inline-block;
    margin-top: 1rem;
}

.input-wrapper>svg {
    margin: 1rem 0 1rem 1rem;
}

form span {
    color: rgb(180, 0, 0);
    font-size: 0.8rem;
}