.shop {
    padding: 0rem 0.5rem;
    padding-bottom: 2rem;
    max-width: 1080px;
    width: 100%;
    margin: auto;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    padding-bottom: 0.8rem;
}

@media screen and (max-width: 768px) {
    .product-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (width: 768px) {
    .product-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}


.product-card {
    position: relative;
    width: 100%;
    max-width: 320px;
    background-color: rgb(230, 230, 230);
    border-radius: 4%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.25s ease-in-out;
    animation: fadeInUp 0.5s ease-in-out;

    a {
        @include flex(center, center, column)
    }

    svg {
        position: absolute;
        z-index: 9999;
        top: 0;
        right: 0;
        background: white;
        margin: 0.5rem;
        padding: 0.3rem;
        border-radius: 50%;
        color: $color-dark;
        z-index: 9999;
    }

    @media (max-width: 600px) {
        margin-bottom: 1rem;
        max-width: none;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(40px);
        /* Сдвиг вниз */
    }

    100% {
        opacity: 1;
        transform: translateY(0);
        /* Вернуться в нормальное положение */
    }
}

.product-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

@media (hover: none) {
    .product-card:hover {
        transform: none;
    }
}

.product-img {
    height: 250px;
    @include flex(center, center, row);
    border-radius: 4%;
    margin: 5px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    a {
        height: 100%;
    }

    @media screen and (max-width: 912px) {
        height: 9rem;
    }
}

.product-info {
    margin-top: 1rem;
    margin-bottom: 1rem;

    h3,
    p {
        text-align: center;
        font-size: 0.8rem;
        margin: 0rem;
    }

    h3 {
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.product-detail-img {
    flex: 2;
    height: 65vh;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        /* Ширина изображения будет равна ширине родительского контейнера */
        height: 100%;
        /* Высота изображения будет равна высоте родительского контейнера */
        object-fit: contain;
        /* Изображение заполняет контейнер, сохраняя пропорции, обрезая лишнее */
        display: block;
        /* Избегаем пробелов снизу изображения */
    }
}

@media (max-width: 768px) {
    .product-detail-img {
        height: 40vh;
        align-items: center !important;
        justify-content: center !important;
    }

}

.product-detail-about {
    min-width: 30%;

    input,
    select {
        width: 100%;
    }

    h2 {
        font-size: 1rem;
        font-weight: 600;
    }

    h1 {
        font-size: 1.3rem;
        margin-top: 1rem;
    }

    p:nth-child(3) {
        margin-top: 0.7rem;
        font-size: 1rem;
    }

    p {
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    @media (max-width: 768px) {
        max-width: none;
    }
}

.custom-navigation .custom-prev,
.custom-navigation .custom-next {
    background-color: #7a7a7a;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    color: white;
    border: 1px solid black;
    cursor: pointer;
    margin: 0 !important;
    padding: 0 !important;
    top: 50%;
    opacity: 0.5;
}

.custom-navigation .custom-prev:hover,
.custom-navigation .custom-next:hover {
    background-color: #7a7a7a;
    opacity: 1;
}

.load-more {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    margin-top: 0.5rem;
    border-radius: 5px;
    font-size: 0.7rem;

    &:hover {
        background-color: transparent;
        border-color: black;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
}

.product-description {
    border-top: 2px solid black;
    padding-top: 0.5rem;
}