.admin {
  width: 100%;
  padding: 1rem;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    list-style-type: none;
    padding: 0;
    margin: 0 0 1rem 0;
    border: 1px solid $color-dark;
    border-radius: 8px;
    background-color: $color-smoke;
  }

  li {
    flex: 1;
    text-align: center;

    button {
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      border: none;
      cursor: pointer;
      font-size: 1rem;

      &.active {
        color: white;
        background-color: $color-dark;
      }

      &:hover {
        background-color: lighten($color-dark, 20%);
      }
    }
  }

  li.active {
    color: white;
    background-color: $color-dark;
    border-radius: 7px;
  }

  input,
  textarea,
  select {
    width: 100%;
  }
}

.text-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid $color-dark;
  border-radius: 8px;
  background-color: $color-smoke;

  h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .ck-editor__editable_inline {
    min-height: 200px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 1rem;
  }

  button {
    background-color: $color-dark;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
      background-color: lighten($color-dark, 10%);
    }
  }

  @media (max-width: 600px) {
    padding: 0.5rem;

    h3 {
      font-size: 1rem;
    }

    button {
      font-size: 0.9rem;
    }
  }
}

.admin-product {
  @include flex(space-between, flex-start, row);
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
}

.product-panel {
  padding: 1rem;
  @include flex(center, center, column);
  width: 100%;
  max-width: 40rem;

  h2 {
    font-size: 1.2rem;
    margin-bottom: 12px;
  }

  input {
    padding: 0.5rem;
  }

  @media (max-width: 1000px) {
    padding: 1rem 0;
    max-width: none;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}