.hero {
    z-index: -1;
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    height: 20rem;
    min-height: 20vh;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 50px 50px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0 0 50px 50px;
    }
}

.hero-circle {
    position: absolute;
    top: 30%;
    right: 78%;
    z-index: 1;
    border-radius: 50%;
    height: 13rem;
    width: 13rem;
    background: transparent;

    @media (max-width: 1040px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.hero-about {
    position: sticky;
    display: flex;
    padding: 5rem 0 5rem 1rem;
    max-width: 1080px;
    width: 100%;
    margin: auto;

    h3 {
        padding: 0rem 0rem;
        font-size: 3rem;
        margin: 1rem 0;
    }

    p {
        font-size: 1rem;
    }

    button {
        margin-top: 1rem;
        width: 100%;
        background: $color-dark;
        border: 2px solid $color-dark;
        font-size: 1rem;
    }

    @media (max-width: 800px) {
        padding-top: 2rem;

        h3 {
            font-size: 2.5rem;
        }
    }
}