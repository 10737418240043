.order-summary {
    color: $color-grey;
    background: $color-smoke;
    border: 1px solid $color-lightgrey;
    padding: 1rem;
    width: 100%;
    margin-top: 1rem;

    button {
        border-radius: 0px;
    }

    .line {
        background: $color-lightgrey;
    }

    @media (max-width: 850px) {
        flex-wrap: wrap-reverse;
        margin: 1rem 0 0 0;
        max-width: none;
        width: 100%;
    }
}

.checkout-right {
    @include flex(flex-start, flex-start, column);
    width: 100%;

    @media (min-width: 1100px) {
        margin-left: 4rem;
    }
}

.payment-method {
    width: 100%;
    margin-bottom: 1rem;
}

.discount-code {
    @include flex(space-between, center, row);
    gap: 1rem;
    width: 100%;
    margin-top: 1rem;
    flex-direction: column;

    button,
    input {
        border-radius: 0px;
        margin: 0;
    }

    button {
        border: none;
    }
}


.complete {
    width: 100%;
    align-items: center;
    justify-content: center;
    @include flex(center, center, column);

    svg {
        height: 8rem;
        width: 8rem;
    }

    button {
        margin-top: 1rem;
    }

    h1 {
        font-size: 1rem;
    }
}

.complete-btn {
    padding: 1rem;
    background: transparent;
    color: black;
    font-size: 0.6rem;
    font-weight: bold;
    border: 2px solid black;
    border-radius: 5px;
    letter-spacing: 1px;
    width: fit-content !important;
    margin-bottom: 0 !important;

    &:hover {
        background-color: transparent;
        border-color: black;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    }
}

.spinner {
    padding-top: 5rem;
}