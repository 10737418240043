* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  line-height: 145%;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  color: $color-dark;
}

h1,
h2 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

h3,
h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

ul {
  list-style: none;
}

li {
  font-size: 0.8rem;
  list-style: none;
  padding: 0.5rem 1rem;
}

input,
textarea,
select {
  padding: 0.75rem;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid $color-lightgrey;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  resize: none;
}

select {
  cursor: pointer;
}

select {
  appearance: none;
  -webkit-appearance: none;
  padding: 0.5rem;
  background: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http://www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M4%206L8%2010L12%206%22%20stroke%3D%22currentColor%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22/%3E%3C/svg%3E') no-repeat right center;
  width: 100%;
  background-position: right 5px center;
}

button {
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  background: $color-blue;
  font-size: 0.6rem;
  font-weight: bold;
  color: white;
  border: 2px solid $color-blue;
  border-radius: 5px;
  flex-grow: 1;
  letter-spacing: 1px;
  transition: all 0.1s ease-in-out;

  &:hover {
    background: $lighter-main;
    border: 2px solid $lighter-main;
  }
}

label {
  font-size: 0.8rem;
  width: 100%;

}

svg.svg-inline--fa {
  height: 1rem;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

p {
  font-size: 0.8rem;
}

a {
  font-size: 0.8rem;
  text-decoration: none;
  color: black;
  cursor: pointer;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: text;
}

input[type=radio]:checked {
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

table {
  width: 100%;
  box-sizing: border-box;
  border-spacing: 0px;

  button {
    margin: 0;
    padding: 0.5rem;
  }
}

th,
td {
  padding: 15px;
}

tr {
  th {
    font-size: 0.8rem;
    font-weight: lighter;
    background: $color-dark;
    text-align: left;
    color: white;

  }

  &:nth-child(2n) {
    background: $color-smoke;
  }
}

td {
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  th {
    text-align: center;
  }

  td {
    border-bottom: none;
  }

  td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}