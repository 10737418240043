h1,
h2 {
  font-size: 24px;
  color: #333;
  margin: 10px 0;
}

h3,
h4 {
  color: #444;
  margin: 8px 0;
}

p {
  font-size: 16px;
  color: #555;
  margin: 5px 0;
  line-height: 1.5;
}

.delivery-item,
.payment-item {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.delivery-info,
.payment-info {
  padding: 10px;
  background-color: #f9f9f9;
  border-left: 3px solid #eee;
}

.info-content {
  padding-left: 8px;
  padding-right: 8px;
}