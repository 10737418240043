.filter-control {
    @include flex(flex-start, center, row);
    width: 100%;
    gap: 0.5rem;
    margin-bottom: 1rem;

    select,
    input {
        margin-bottom: 0rem;
        height: 100%;
        padding: 0.5rem;
    }

    select {
        min-width: 20ch;
    }
}

.filter-search {
    .input-wrapper {
        max-width: 500px;
        margin: auto;
        margin-bottom: 2rem;
    }

}

.filter-div {
    margin-right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
        display: block;
        padding: 0.25rem;
        margin-top: 0.6rem;
        transform: translateY(-2px);
    }

    label {
        margin-right: 0.5rem;
    }
}

.filter-option {
    display: flex;
    transform: translateX(-100%);
    /* Изначально скрываем элемент за левую границу */
    opacity: 0;
    /* Прозрачный */
    transition: transform 0.3s ease-in, opacity 0.5s ease-in;
    /* Плавная анимация */
    flex-direction: row;
    visibility: hidden;
    /* Элемент не виден */

    @media (max-width: 570px) {
        flex-direction: column;
        row-gap: 0.5rem;
    }
}

.filter-option.open {
    transform: translateX(0);
    /* Возвращаем элемент в его нормальное положение */
    opacity: 1;
    /* Элемент полностью виден */
    visibility: visible;
    /* Элемент становится видимым */
}

.filter-option.closing {
    transform: translateX(-100%);
    /* Анимация закрытия */
    opacity: 0;
    visibility: visible;
    /* Видимость для анимации */
    transition: transform 0.3s ease-in, opacity 0.2s ease-in;
}


.filter-spec {
    @include flex(flex-start, center, row);

    p {
        padding: 0 0.5rem;
    }

    input {
        width: 100px;
    }
}

input[id=maxPrice],
input[id=minPrice] {
    width: 80px;
}