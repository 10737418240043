.swiper-main-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  /* Центрируем слайдер */
  align-items: center;
  margin-top: 0.5rem;
}

.swiper-inner-container {
  width: 90%;
  /* Ширина самого слайдера, оставляем 10% для кнопок */
  max-width: 100%;
  /* Не даем контейнеру вылезать за пределы */
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 2.65rem;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  /* Ширина кнопки */
  height: 5rem;

  /* Высота кнопки */
  @media (max-width: 500px) {
    width: 1rem;
  }
}

.swiper-button-next {
  right: 5px;
  /* Позиционируем кнопку за пределами слайдера */
}

.swiper-button-prev {
  left: 5px;
  /* Позиционируем кнопку за пределами слайдера */
}


/* Скрываем текст кнопок */
.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.swiper-pagination {
  bottom: 0.5rem;
  /* Положение индикаторов */
}

.swiper-pagination-bullet {
  background: rgb(97, 97, 97);
  border: 0.125rem solid rgb(0, 0, 0);
  /* Цвет и толщина обводки */
  border-radius: 50%;
  /* Закругление углов для создания круга */
  width: 1rem;
  /* Ширина точки */
  height: 1rem;
  /* Высота точки */
}

.swiper-pagination-bullet-active {
  background: rgb(255, 255, 255);
  border: 0.125rem solid black;
}