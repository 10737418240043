.header-container {
    width: 100%;
    margin: auto;
    max-width: 1200px;
    margin: auto;
    padding: 1rem;
    flex: 1;
    border-top: 1px solid $color-lightgrey;
    border-bottom: 1px solid $color-lightgrey;
    @include flex(space-between, center, row);

    ul {
        @include flex(space-between, center, row);
        gap: 1.5rem;

        @media (max-width: 1040px) {
            display: none;
        }
    }

    a h1 {
        margin-bottom: 0;
        max-width: 12rem;
    }
}

@media (max-width: 1040px) {
    .header-container a h1 {
        max-width: 10rem;
    }
}

@media (max-width: 480px) {
    .header-container a h1 {
        max-width: 8rem;
    }
}

.burger {
    font-size: 2rem;
    margin-left: 1rem;
    display: none;

    @media (max-width: 850px) {
        display: block;
    }
}

.header-tools {
    flex: 1;
    @include flex(flex-end, flex-end, row);

    svg {
        padding: 0.5rem;
    }
}

.svg-icon {
    height: 40px;
    width: 35px;
    position: relative;
    cursor: pointer;
    @include flex(center, center, row);

    span {
        @include flex(center, center, row);
        z-index: 9999;
        top: 10%;
        right: 0;
        border: 2px solid white;
        height: 18px;
        width: 18px;
        text-align: center;
        border-radius: 50%;
        background: $color-blue;
        color: white;
        font-size: 0.6rem;
        position: absolute;
    }
}

.header-second {
    width: 100%;
    margin: auto;
    @include flex(center, center, row);
    background: $color-smoke;

    @media (max-width: 1040px) {
        display: none;
    }
}

.header-second-msg {
    @include flex(space-between, center, row);
    max-width: 1080px;
    width: 100%;
    padding: 0.25rem 1rem;

    p {
        font-weight: bold;
        color: #666666;
        font-size: 0.7rem;
    }

    p span {
        color: $color-blue;
    }
}

.header-second-msg p:first-child {
    flex: 1;
    text-align: left;
}

.header-second-msg p:nth-child(2) {
    flex: 1;
    text-align: center;
}

.header-second-msg p:last-child {
    flex: 1;
    text-align: right;
}

.header-main {
    flex: 1;
}

.header-search {
    margin: 0 auto;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: height 0s ease, opacity 0.3s ease;
    max-width: 1200px;
    border-bottom: 1px solid $color-lightgrey;

    .input-wrapper {
        border: none;
        max-width: 1080px;
        margin: auto;

        input {
            width: 100%;
        }
    }

    &.active {
        height: 100%;
        opacity: 1;
    }
}

.header-line {
    height: 1px;
    background: $color-lightgrey;

    &.active {
        display: none;
    }
}


.header-contacts-icon {
    display: none;
}

@media (max-width: 1040px) {
    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 60px;
    }

    .header-section {
        position: absolute;
    }

    .header-tools {
        position: absolute;
        right: 1rem; // Инструменты остаются справа
    }

    .header-contacts-icon {
        display: flex;
        position: absolute;
        left: 1rem;
    }
}

.header-contacts-content {
    display: none;
    width: 100%;

    &.active {
        display: none;
        border-bottom: 1px solid $color-lightgrey;
        padding-left: 15px;

        @media (max-width: 1040px) {
            display: grid;
        }
    }
}

.social-contact-icon {
    width: 35px;
    padding: 0.3rem;

    a {
        @include flex(center, center, row);
    }

    svg {
        height: 24px;
        width: 24px;
    }
}


.header-contacts-content {
    display: flex;
    flex-direction: column;
}

.contact-item {
    display: flex;
    align-items: center;
}

.social-contact-icon {
    margin-right: 10px;
}

.contact-text {
    font-size: 16px;
    color: #333;
    margin-right: 10px;
}

.message-box {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.search-back-btn {
    width: auto !important;
    padding: 0.4rem !important;
    margin-bottom: 0rem;
    margin-right: 1rem;
    margin-left: 3px;
    background: transparent;
    font-size: 0.7rem;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    flex-grow: 1;

    &:hover {
        background-color: transparent;
        border-color: black;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
}

.contacts-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    padding: 0.3rem;
    cursor: pointer;

    svg {
        height: 24px;
        width: 24px;
    }
}